import "./styles2/Footer.css";
import Button from "react-bootstrap/Button";
import LLA from "../images/Group 467.svg";
import linkedIn from "../images/LinkedIn.svg";
import twitter from "../images/Twitter.svg";
import LogoCopy from "../images/Logo copy.svg";
import footimg from "../images/Layer-511-copy.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Footer() {
  return (
    <>
      <div className="plane5">
        <div className="con-center-box-footer row">
          <div className="box-footer col-sm-6">
            <h1 className="learn">Learn More.</h1>
            <div className="con-box2-footer col-sm-6">
              <p style={{ lineHeight: "24px" }}>
                Want to understand more about how Ecosystm can help, please
                visit our contact us page and we will be in touch!
              </p>
            </div>
            <div>
              <button
                className=" py-2 cursor-pointer  mt-5"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "https://www.team.com/#/contact-us";
                }}
                style={{
                  borderRadius: "35px",
                  border: "1px solid #b8b8b8",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  marginLeft: "5%",
                  background: "none",
                  color: "white",
                }}
              >
                <span style={{ paddingLeft: "10px" }}>Contact Us</span>
                <img
                  className=""
                  style={{
                    width: "40px",
                    marginRight: "10px",
                    height: "40px",
                    marginLeft: "20px",
                    transform: "rotate(270deg)",
                  }}
                  alt=""
                  src={LLA}
                />
              </button>
            </div>
          </div>
          <LazyLoadImage className="col-sm-6 fooimg" alt="" src={footimg} />
        </div>
        <div className="FooterNav">
          <ul className="UlFoot">
            <li className="t-all">
              <a className="foot" href="https://team.ecosystm.io/#/contact-us">
                Contact Us
              </a>
            </li>
            <li className="t-all">
              <a
                className="foot"
                href="https://team.ecosystm.io/#/privacy-policy"
              >
                Privacy Policy
              </a>
            </li>
            <li className="t-all">
              <a
                className="foot"
                href="https://team.ecosystm.io/#/terms-of-use"
              >
                Terms of Use
              </a>
            </li>
            <li className="t-all">
              <a href="https://twitter.com/ecosystm360">
                <LazyLoadImage className="foot" alt="" src={linkedIn} />
              </a>
              <a href="https://www.linkedin.com/company/ecosystm360/mycompany/">
                <LazyLoadImage className="foot" alt="" src={twitter} />
              </a>
            </li>
          </ul>
        </div>
        <hr className="fooHr" />
        <div style={{ marginTop: "40px", marginBottom: "20px" }}>
          <LazyLoadImage className="logocopy" alt="" src={LogoCopy} />
          <h4 className="copyright">©2023 Ecosystm. All rights reserved</h4>
        </div>
      </div>
    </>
  );
}

export default Footer;
