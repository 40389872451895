import Accordion from "react-bootstrap/Accordion";
import "./styles1/FlushExample.css";
import accImg from "../images/about Ecosystm.svg";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { LazyLoadImage } from "react-lazy-load-image-component";

function FlushExample() {
  return (
    <>
      <Accordion flush>
        <Accordion.Item className="AItem" eventKey="0">
          <Accordion.Header>
            <span className="Ahead">
              About Ecosystm<em>.</em>
            </span>
          </Accordion.Header>
          <Accordion.Body className="abody">
            <p>
              With more than 150 of the world’s largest companies as our
              clients, Ecosystm works with some of the biggest technology
              providers and enterprises from across industries to help make the
              best decisions in this evolving digital economy.
            </p>
            {/* <p>We bring the following services:</p>
            <ul>
              <li>Research-Led Strategy</li>
              <li>
                Development Go-to-Market Strategy & Exceution for Technology
                Providers
              </li>
              <li>Digital Blueprint Development & Execution for Enterprises</li>
            </ul> */}

            <p>
              Ecosystm’s technology augmented research is based on our real-time
              market data supported by some of the most relevant leading
              analysts comprising highly experienced analysts and consultants
              coupled with a team of very experienced practitioners who
              themselves have been C-suite leaders in the enterprise world.
            </p>
            <center>
              <LazyLoadImage className="aimg" alt="" src={accImg} />
            </center>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" id="OurOfferings">
          <Accordion.Header>
            <span className="Ahead">
              Our Offerings<em>.</em>
            </span>
          </Accordion.Header>
          <Accordion.Body className="abody">
            <Row>
              <Col
                style={{ marginRight: "10px", marginBottom: "20px" }}
                className="border bg-white rounded"
              >
                <div className="badge  my-3">
                  <h3 className="h3">1</h3>
                </div>
                <h2>Market Research: Opportunity and Competitive Assessment</h2>
                <ul>
                  <li>Partner Identification, Assessment and Profiling</li>
                  <li>Sales Enablement</li>
                  <li>Account Profiling</li>
                  <li>Brand Health & NPS Research</li>
                </ul>
              </Col>
              <Col
                style={{ marginRight: "10px", marginBottom: "20px" }}
                className="border bg-white rounded"
              >
                <div className="badge  my-3">
                  <h3 className="h3">2</h3>
                </div>
                <h2> M&A and Investment Research</h2>
                <ul>
                  <li>M&A Target Identification and Assessment</li>
                  <li>Business & Investment Opportunity Assessments</li>
                  <li>Market Entry & Segment Analysis</li>
                  <li>Competitive Market Analysis</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col
                style={{ marginRight: "10px", marginBottom: "20px" }}
                className="border bg-white rounded"
              >
                <div className="badge my-3">
                  <h3 className="h3">3</h3>
                </div>
                <h2>Thought leadership Content and Leadership Roundtables</h2>
                <ul>
                  <li> Whitepapers, case studies, ebooks</li>
                  <li> Strategic account workshops</li>
                  <li> Speaking engagements</li>
                  <li> Executive roundtables</li>
                </ul>
              </Col>
              <Col
                style={{ marginRight: "10px", marginBottom: "20px" }}
                className="border bg-white rounded"
              >
                <div className="badge  my-3">
                  <h3 className="h3">4</h3>
                </div>
                <h2>Management Consulting for Digital Transformation</h2>
                <ul>
                  <li>Strategy Development</li>
                  <li>Business Case Development</li>
                  <li>Procurement & Contracting</li>
                  <li>Program Governance</li>
                </ul>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default FlushExample;
