import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles1/RevTwoCol.css";
import EcoTV from "../images/EcosystmTV.png";
import LL from "../images/Group 469 (1).svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

function RevTwoCol() {
  return (
    <>
      <Row xs={1} md={2} className="g-4-Rev flex" id="EcosystmTV">
        <Col className="RevleftCol-Rev">
          <LazyLoadImage className="img-fluid" alt="" src={EcoTV} />
        </Col>

        <Col className="RevrightCol-Rev">
          <h1 className="ETV">
            EcosystmTV<em>.</em>
          </h1>
          <p>
            Interviews from business leaders, opinions from Ecosystm Analysts
            and EcosystmTV Bytes.
          </p>
          <br />
          <center>
            <hr className="hr" />
          </center>
          <br />
          {/* <center>
          <div className="d-grid gap-2 Revbttn">
            <Button onClick={(e) => {
      e.preventDefault();
      window.location.href='https://blog.ecosystm360.com/ecosystm-tv/';
      }} className="Revbtn" size="lg">
              <span className="rev-txt-btn">Watch EcosystmTV</span><pre><LazyLoadImage className="LL abc" alt="" src={LL}/></pre>
            </Button>
          </div>
          </center> */}
          <div>
            <button
              className="bg-white py-2 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "https://blog.ecosystm.io/ecosystm-tv/";
              }}
              style={{
                borderRadius: "35px",
                border: "1px solid #b8b8b8",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ paddingLeft: "10px" }}> Watch EcosystmTV </span>
              <img
                className=""
                style={{
                  width: "40px",
                  marginRight: "10px",
                  height: "40px",
                  marginLeft: "20px",
                  transform: "rotate(270deg)",
                }}
                alt=""
                src={LL}
              />
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default RevTwoCol;
