import "./styles1/Management.css";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Grp1 from "../images/Group 13.png";
import Grp2 from "../images/Group 13 copy.png";
import Grp3 from "../images/Group 13 copy 2.png";
import LL from "../images/Group 469 (1).svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Management() {
  return (
    <>
      <div className="plane2">
        <center>
          <div className="community">
            <div className="pill">ECOSYSTM COMMUNITY</div>
          </div>
          <h1 className="hel">
            Executive Engagements<em>.</em>
          </h1>
          <h5 className="hel2">
            Invitation only gatherings hosted by Ecosystm analysts where
            industry leaders gather to learn, share, and network with other
            senior business, technology, and digital leaders.
          </h5>
          <CardGroup className="cdgrp">
            <Card className="m-3 myth" style={{ borderRadius: "10px" }}>
              <Card.Img
                className="img-fluid"
                style={{ borderRadius: "10px" }}
                variant="top"
                src={Grp1}
              />
            </Card>
            <Card className="m-3" style={{ borderRadius: "10px" }}>
              <Card.Img
                className="img-fluid"
                style={{ borderRadius: "10px" }}
                variant="top"
                src={Grp2}
              />
            </Card>
            <Card className="m-3 myth" style={{ borderRadius: "10px" }}>
              <Card.Img
                className="img-fluid"
                style={{ borderRadius: "10px" }}
                variant="top"
                src={Grp3}
              />
            </Card>
          </CardGroup>
        </center>
        <div
          style={{
            paddingBottom: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a
            href="https://blog.ecosystm.io/events/"
            style={{ textDecoration: "none" }}
          >
            <button
              style={{
                borderRadius: "35px",
                border: "1px solid #b8b8b8",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              className=" "
              size="md"
            >
              <span style={{ paddingLeft: "10px" }} className="">
                View All Ecosystm Gatherings
              </span>
              <LazyLoadImage
                className=""
                alt=""
                style={{
                  width: "40px",
                  marginRight: "10px",
                  height: "40px",
                  marginLeft: "20px",
                  transform: "rotate(270deg)",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
                src={LL}
              />
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Management;
